import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Definition`}</h2>
    <p><a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Responsive_Design"
      }}>{`Responsive web design`}</a>{` is the practice of building a website suitable to work on every device and every screen size, no matter how large or small, mobile or desktop. Responsive web design is an accessibility requirement, focused around providing an intuitive and gratifying experience for everyone.`}</p>
    <p>{`At GitHub, being Responsive means our experiences are inherently adaptive. Interfaces should not only adjust layout and spacing when resizing a page, but work efficiently to provide an experience that is tailored to match the paradigms and affordances of the person’s device:`}</p>
    <ul>
      <li parentName="ul">{`Responsive to the `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Form_factor_(design)"
        }}>{`form factor`}</a>{`: adapt to viewport size, pointing device support, and to the device metaphors, power, and `}<a parentName="li" {...{
          "href": "https://www.interaction-design.org/literature/topics/affordances"
        }}>{`affordances`}</a>{`.`}</li>
      <li parentName="ul">{`Responsive to the user preferences: respect browser’s default font size, reduced motion, color scheme, contrast preferences, etc.`}</li>
    </ul>
    <h2>{`Responsive to the device’s form factor`}</h2>
    <p>{`Designing for the web means that people can open your page from virtually any type of device. When designing for GitHub, picture our users using desktops, tablets, and smartphones, but also `}<a parentName="p" {...{
        "href": "https://www.reddit.com/r/cyberDeck/top/?t=year"
      }}>{`custom-built tiny cyberdecks`}</a>{` and `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Virtual_reality_headset"
      }}>{`VR headsets`}</a>{`.`}</p>
    <h3>{`Device power`}</h3>
    <p>{`GitHub cannot discriminate against devices and connection speeds. Experiences need to be fast and performant, even on low-powered devices and slow connections. It’s not fully shipped until it’s fast.`}</p>
    <h3>{`Viewport size`}</h3>
    <p>{`To guarantee maximum compatibility, pages should adapt to the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Viewport_concepts"
      }}>{`browser’s viewport size`}</a>{`, without loss of information or functionality, starting at the following dimensions:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Minimum viewport width`}</strong>{`: 320px`}</li>
      <li parentName="ul"><strong parentName="li">{`Minimum viewport height`}</strong>{`: 256px`}</li>
    </ul>
    <p>{`Providing support at these smaller sizes enable people with low vision to use GitHub with a browser zoom enabled, up to 400% on a 1280px wide screen. `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/reflow.html"
      }}>{`Read more about this accessibility requirement`}</a>{`.`}</p>
    <p>{`To understand how to break down a page to work on smaller viewports, check out `}<a parentName="p" {...{
        "href": "/foundations/layout#responsive-foundations"
      }}>{`Responsive foundations`}</a>{` and `}<a parentName="p" {...{
        "href": "/foundations/layout#responsive-behavior"
      }}>{`Responsive behavior`}</a>{` sections in the `}<a parentName="p" {...{
        "href": "/foundations/layout"
      }}>{`Layout`}</a>{` page.`}</p>
    {/*
     Todo: Link to Figma Templates
     > When designing a mobile-friendly rendering of a page, we recommend using a viewport width of **375px**, as this is the most common size among small smartphones. However, the page should still be tested and be fully functional at smaller widths for accessibility reasons, all the way to **320px**.
     */}
    <h3>{`Pointing device`}</h3>
    <p>{`Consider multimodal input capabilities when designing interfaces. Many devices possess the ability to respond to cursor, touch, keyboard, voice, and other forms of input simultaneously. Additionally, many devices have the ability to have their input augmented in ways you may not initially expect. For example, many smartphones can support mouse and keyboard-based input, although their browsers may not be able to detect the presence of this augumentation.`}</p>
    <p>{`Because of this, pointing device media queries such as `}<inlineCode parentName="p">{`coarse`}</inlineCode>{` or `}<inlineCode parentName="p">{`fine`}</inlineCode>{` are unreliable. Avoid relying on this type of query to conditionally modify parts of an interface.`}</p>
    <h4>{`Minimum target`}</h4>
    <p>{`When creating interactive targets in your design, ensure that they are large enough to easily tap or click on. The AA accessibility standard GitHub strives for requires a minimum target size of `}<inlineCode parentName="p">{`24px`}</inlineCode>{`.
However, it is recommended to aim for the AAA standard when possible. For more information on Target Size at the AAA level, refer to the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/target-size.html"
      }}>{`W3C documentation`}</a>{`.`}</p>
    <p>{`A common example of a hit target that may not meet the AAA standard on mobile is our `}<inlineCode parentName="p">{`medium`}</inlineCode>{` size button, which is visually only 32px in height. Consider utilizing the `}<inlineCode parentName="p">{`large`}</inlineCode>{` button size for circumstances like this, instead of attempting to adjust the button's touch target area to meet the AAA size requirement.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/980622/212349806-8329b722-9245-4911-9763-87a7eba6b328.png" role="presentation" width="456" alt="A design that showcases a medium-sized button of 32px height, where the touch target has been increased to 44px by adding space above and below the button." />
    <Caption mdxType="Caption">While acceptable for AA try to increase the touch target to 44px on mobile to reach AAA</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/980622/212349813-b5389387-72b5-47bc-9c3a-ffa7c5609887.png" role="presentation" width="456" alt="A design that illustrates a medium-sized button that is 32px in height and its touch target remains unchanged." />
    <Caption mdxType="Caption">Keep the 32px hit target for mobile touch targets.</Caption>
  </Dont>
    </DoDontContainer>
    {/*
     Todo: Touch-friendly strategy section.
     Examples of touch adaptation with out-of-boudaries touch targets, when applied to Button and IconButton components, following comparison with ActionListItem, which increases its height instead.
     Image source:
     https://www.figma.com/file/YlBTqHTbdRwYQt0bfIHvGw/Interface-guidelines?node-id=457%3A89625
     */}
    <h4>{`Hover support`}</h4>
    <p>{`Browsers report if the primary input mechanism can hover over elements with the `}<inlineCode parentName="p">{`hover`}</inlineCode>{` media feature.`}</p>
    <p>{`Devices that don’t support hovering such as smartphones and tablets may need adapted experiences so that the user can interact with the page without hovering over elements.`}</p>
    <p>{`Features that rely on hover such as tooltips or hovercards may not be available on these devices. Make sure the information presented is still accessible through other means, such as a direct link to a page with the information.`}</p>
    {/*
     Todo: Do we include examples of device metaphors and afforances here as well?
     */}
    <h2>{`Responsive to the user preferences`}</h2>
    <p>{`People may set system preferences to change the way they prefer to interact with their devices. By default, GitHub must respect these preferences. Providing a way to override these options within the `}<strong parentName="p">{`User settings`}</strong>{` is also recommended.`}</p>
    <p>{`On the web, these user preference media features include:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-color-scheme"
        }}><inlineCode parentName="a">{`prefers-color-scheme`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-contrast"
        }}><inlineCode parentName="a">{`prefers-contrast`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion"
        }}><inlineCode parentName="a">{`prefers-reduced-motion`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/@media/forced-colors"
        }}><inlineCode parentName="a">{`forced-colors`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/@media/inverted-colors"
        }}><inlineCode parentName="a">{`inverted-colors`}</inlineCode></a></li>
    </ul>
    <h3>{`Browser default font size`}</h3>
    <p>{`Users may set their operating system or browsers to use larger or smaller fonts. GitHub should respect these preferences, as it enables people with low vision to increase the size of the text on the page.`}</p>
    <p>{`Primer design tokens are designed to support `}<inlineCode parentName="p">{`rem`}</inlineCode>{` units, which are relative to the browser’s default font size. Use Primer-provided design tokens to ensure consistency across the system.`}</p>
    <p>{`To test this, change the size in the browser:`}</p>
    <ul>
      <li parentName="ul">{`In Chrome: Go to `}<inlineCode parentName="li">{`chrome://settings`}</inlineCode>{`. Under `}<strong parentName="li">{`Appearance`}</strong>{`, change the font size.`}</li>
      <li parentName="ul">{`In Firefox: In the menu button, select `}<strong parentName="li">{`Settings`}</strong>{`. Under ”Language and Appearance” you can set different size. Default is `}<inlineCode parentName="li">{`16`}</inlineCode>{`.`}</li>
    </ul>
    <h2>{`See also`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/foundations/layout#viewport-ranges"
        }}>{`Viewport ranges`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/foundations/layout#breakpoints"
        }}>{`Responsive breakpoints`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/foundations/layout#responsive-behavior"
        }}>{`Responsive layout behavior`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      